<template>
    <div class="login-page">
        <header>
            <div class="login-page__head">
                <!-- <div class="logo-img">
                    <img src="@assets/images/login_logo.jpg" alt="">
                </div> -->
                <div class="text">{{ systemName }}</div>
                <div class="dialog">
                    <span>{{ systemTagName }}</span>
                </div>
            </div>
        </header>
        <section class="login-page__content">
            <div class="container-wrap">
                <transition>
                    <div class="container" :class="{ 'container--register': !isLogin }">
                        <div v-show="isLogin">
                            <div class="head">用户登录</div>
                            <div class="content-padding">
                                <a-form-model
                                    v-show="isLogin"
                                    ref="form"
                                    :model="form"
                                    :rules="rules">
                                    <!--                            <a-alert -->
                                    <!--                                class="login-page__error-box" -->
                                    <!--                                type="error" -->
                                    <!--                                show-icon -->
                                    <!--                                :message="errorMessage" -->
                                    <!--                            /> -->

                                    <a-form-model-item prop="account">
                                        <a-input
                                            v-model="form.account"
                                            size="large"
                                            placeholder="请输入手机号"
                                        >
                                            <div slot="prefix" :style="{ color: 'rgba(0,0,0,.25)' }">
                                                <img
                                                    width="20x"
                                                    src="@assets/images/icon/phone.png"
                                                    alt=""
                                                    srcset=""
                                                >
                                            </div>
                                        </a-input>
                                    </a-form-model-item>
                                    <a-form-model-item prop="password">
                                        <a-input-password
                                            v-model="form.password"
                                            size="large"
                                            placeholder="请输入登录密码"
                                        >
                                            <div slot="prefix" :style="{ color: 'rgba(0,0,0,.25)' }">
                                                <img
                                                    width="20px"
                                                    src="@/assets/images/icon/password.png"
                                                    alt=""
                                                    srcset=""
                                                >
                                            </div>
                                        </a-input-password>
                                    </a-form-model-item>
                                    <a-form-model-item v-if="showVerifyCode" prop="verifyCode">
                                        <a-row>
                                            <a-col :span="14">
                                                <a-input
                                                    v-model="form.verifyCode"
                                                    size="large"
                                                    placeholder="请输入短信验证码"
                                                >
                                                    <div
                                                        slot="prefix"
                                                        :style="{ color: 'rgba(0,0,0,.25)' }"
                                                    >
                                                        <img
                                                            width="20px"
                                                            src="@/assets/images/icon/phone-code.png"
                                                            alt=""
                                                            srcset=""
                                                        >
                                                    </div>
                                                </a-input>
                                            </a-col>
                                            <a-col :span="10">
                                                <a-button class="login-page__get-code-btn" :disabled="disabledSendBtn" @click="handleSendCode('form', 'account')">
                                                    {{ sendText }}
                                                </a-button>
                                            </a-col>
                                        </a-row>
                                    </a-form-model-item>
                                    <a-form-model-item>
                                        <a-button
                                            class="submit-button"
                                            size="large"
                                            type="primary"
                                            html-type="submit"
                                            :loading="loading"
                                            :disabled="loading"
                                            @click="handleSubmit"
                                        >
                                            登录
                                        </a-button>
                                    </a-form-model-item>
                                </a-form-model>
                                <div class="form-footer">
                                    <a v-show="showForgetPsdBtn" @click="handleForgetPsd">忘记密码</a>
                                    <span v-show="!showForgetPsdBtn" />
                                    <span>
                                        没有账号，去
                                        <a type="link" @click="type='regiser'"> 注册</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div v-show="!isLogin">
                            <div class="head">用户注册</div>
                            <register @login="type='login'" @forget-psd="handleForgetPsd" />
                        </div>
                        <div v-show="isLogin && citizenQrCodeUrl" class="third-party-link">
                            <a type="link" target="__blank" :href="citizenQrCodeUrl">
                                德阳市民通APP扫码登录
                                <a-icon type="right" style="font-size: 8px;" />
                            </a>
                        </div>
                    </div>
                </transition>
            </div>
        </section>
        <footer class="login-page__footer">
            <!-- <p class="copyright">Copyright © www.cngongbao.com, All Rights Reserved.</p> -->
            <!-- <p class="copyright">主管单位：{{ areaName }}人力资源和社会保障局</p> -->
        </footer>
    </div>
</template>

<script>
import {
    Icon as AIcon,
    FormModel as AFormModel,
    Input as AInput,
    Button as AButton,
    Row as ARow,
    Col as ACol
} from 'ant-design-vue'

import { goForgetPassword } from '@utils/openNewPage'

import VerfiyCodeMixins from '@/mixins/verfiyCode'

import { validatePhoneNumber } from '@/constant/validates'

import { HIDE_ENT_PWD_FORGET_BUTTON_ENABLED } from '@constant/enum'

export default {
    name: 'LoginView',
    components: {
        AIcon,
        AFormModel,
        AFormModelItem: AFormModel.Item,
        AInput,
        AButton,
        AInputPassword: AInput.Password,
        ARow,
        ACol,
        Register: () => import('./Register')
    },
    mixins: [VerfiyCodeMixins],
    data() {
        return {
            form: {
                account: '',
                password: '',
                verifyCode: ''
            },
            loading: false,
            // errorMessage: '',
            type: 'login', // 状态
            isSend: false,
            second: 60
        }
    },
    computed: {
        systemName() {
            return this.$store.getters.systemName || ''
        },
        // areaName() {
        //     return this.$store.getters.globalData.areaName
        // },
        systemTagName() {
            return this.$store.getters.systemTagName || ''
        },
        citizenQrCodeUrl() {
            return this.$store.getters.initInformation?.citizenQrCodeUrl || ''
        },
        isLogin() {
            return this.type === 'login'
        },
        initInformation() {
            return this.$store.getters.initInformation || {}
        },
        showVerifyCode() {
            return this.initInformation.twoFactoryVerificationEnable === '1'
        },
        showForgetPsdBtn() {
            const hideEntPwdForgetButton = this.$store.getters.initInformation?.hideEntPwdForgetButton
            return hideEntPwdForgetButton !== HIDE_ENT_PWD_FORGET_BUTTON_ENABLED
        },
        rules() {
            return {
                account: [
                    {
                        required: true,
                        validator: validatePhoneNumber
                    },
                    {
                        required: true,
                        message: '请输入手机号',
                        trigger: 'blur'
                    }
                ],
                password: [
                    {
                        required: true,
                        message: '请输入登录密码',
                        trigger: 'blur'
                    }
                ],
                verifyCode: [
                    {
                        required: this.showVerifyCode,
                        message: '请输入短信验证码',
                        trigger: 'blur'
                    }
                ]
            }
        }
    },
    methods: {
        handleForgetPsd() {
            goForgetPassword()
        },
        handleSubmit() {
            this.$refs.form.validate(async valid => {
                if (valid) {
                    try {
                        const { redirect = '/' } = this.$route.query || {}
                        await this.$store.dispatch('loginByPassword', this.form)
                        this.$router.replace(
                            {
                                path: redirect
                            },
                            () => {
                            }
                        )
                    } catch (e) {
                        this.$message.error(e.message)
                        // this.errorMessage = e.message || ERROR_DEFAULT_HTTP_REQUEST_FAIL
                    } finally {
                        this.loading = false
                    }
                } else {
                    this.loading = false
                }
            })
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';
@import '@assets/styles/mixins.scss';

.login-page {
    height: 100%;

    header {
        display: flex;
        justify-content: center;
        background: #fff;
        border-bottom: 2px solid #1571e8;
    }

    &__head {
        width: 1200px;
        height: 70px;
        display: flex;
        align-items: center;

        // .logo-img {
        //     margin-left: 364px;
        //     margin-right: 20px;

        //     img{
        //         width: 34px;
        //         height: 34px;
        //     }
        // }

        .text {
            font-size: 20px;
            font-weight: 400;
            color: #323233;
            line-height: 18px;
            letter-spacing: 1px;
        }
    }

    &__content {
        position: relative;
        display: flex;
        justify-content: center;

        width: 100%;
        height: 766px;
        background: #69aae1 url('~@assets/images/login_bg2.png') no-repeat 50%;
        background-size: 1920px 766px;
        background-position: center;

        .container {
            &-wrap {
                position: relative;
                width: 1200px;
            }

            position: absolute;
            top: 140px;
            right: 0;
            width: 400px;
            min-height: 400px;
            background: #fff;
            box-shadow: 0 0 30px 0 rgba(71, 71, 71, 0.04);
            border-radius: 8px;
            transition: all 0.5s;

            &--register {
                top: 160px;
                transform: translateY(-100px);
            }

            .head {
                height: 76px;
                padding: 32px 0 0 30px;
                font-size: 20px;
                color: #333;
                font-weight: bold;
                background: #fff;
                box-shadow: 0 0 30px 0 rgba(71, 71, 71, 0.04);
                border-radius: 8px 8px 0 0;
                border-bottom: 1px solid #e8e8e8;
            }


            //.ant-form-item{
            //   margin-bottom: 16px;
            //}
            //.ant-form-explain{
            //    font-size: 14px;
            //}
            .form-footer {
                margin-top: 16px;
                @include flex();

                &__account-color {
                    color: #969799;
                    margin-right: 5px;
                }

                span {
                    font-size: 14px;
                    color: #969799;
                }
            }
        }

        // login 和register 公用的样式
        .ant-form-item:last-child {
            margin-bottom: 8px;
        }

        .content-padding {
            padding: 40px 30px;
        }

        .ant-input-prefix {
            left: 16px;
        }

        .ant-input-lg {
            height: 56px;
            background: #f7f8fa;
            box-shadow: 0 0 30px 0 rgba(71, 71, 71, 0.04);
            border-radius: 4px;
            border: 1px solid #ededed;
            padding-left: 44px !important;
            font-size: 14px;
        }

        .submit-button {
            width: 100%;
            height: 56px;
            background: linear-gradient(225deg, #5d9ff5 0%, #1877f2 100%);
            box-shadow: 0 0 30px 0 rgba(71, 71, 71, 0.04), 0 3px 6px 0 rgba(87, 156, 245, 0.3);
            border-radius: 2px;
            margin-top: 15px;
        }
    }

    &__footer {
        text-align: center;
        margin: 57px 0 55px;
        font-size: 12px;
        color: #969799;
        line-height: 10px;
    }

    &__get-code-btn {
        color: $base-color;
        border: 1px solid $base-color;
        height: 55px;
        width: 100%;
        border-radius: 0;
        margin-left: -1px;
    }

    .third-party-link {
        text-align: center;
        padding: 16px 30px;
        background-color: #f7f8fa;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        a {
            color: #969799;

            &:hover {
                color: #40a9ff;
            }
        }
    }
}
</style>
