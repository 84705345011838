import axios from '@service'
import { GET_VERIFY_CODE } from './api'
import { HTTP_ERR_SUCCESS } from '@constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@constant/error'

export default async function getPhoneCodeService(payload = {}, config = {}) {
    const param = {
        ...GET_VERIFY_CODE,
        ...config,
        data: payload
    }
    const { data } = await axios(param)
    if (data.code === HTTP_ERR_SUCCESS) {
        return data
    } else {
        throw new Error(data.errorMessage || data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
