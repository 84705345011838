import getPhoneCodeService from '@service/login/getPhoneCodeService'
import getVerifyCodeService from '@service/login/getVerifyCodeService'

export default {
    data() {
        return {
            disabledSendBtn: false,
            sendText: '获取短信验证码',
            labelText: '获取短信验证码',
            setIntervalId: null
            // verifyType: VERIFY_TYPE_PHONE_LOGIN
        }
    },
    methods: {
        async handleSendCode(refsForm = 'refsForm', phoneFieldName = 'phoneNumber') {
            this.$refs[refsForm].validateField(phoneFieldName, errors => {
                if (!errors) {
                    this.handleCheckNumer(phoneFieldName)
                }
            })
        },
        async handleCheckNumer( phoneFieldName = 'phoneNumber') {
            try {
                const phoneNumber = this.form[phoneFieldName]
                this.isLogin ? await getVerifyCodeService({ // 登录
                    phone: phoneNumber
                }) : await getPhoneCodeService({ // 注册
                    phoneNumber
                })
                this.handSetIntervalNumber()
                this.$message.success('发送成功')
            } catch (error) {
                this.$message.error(error.message)
            }
        },
        handSetIntervalNumber() {
            // 短信验证码倒计时
            let setIntervalNumber = 90
            this.sendText = `${this.labelText}(${setIntervalNumber})`
            this.disabledSendBtn = true
            this.setIntervalId = setInterval(() => {
                setIntervalNumber--
                if (setIntervalNumber > 0) {
                    this.sendText = `${this.labelText}(${setIntervalNumber})`
                } else {
                    this.clear()
                }
            }, 1000)
        },
        clear() {
            const { setIntervalId, labelText } = this
            setIntervalId && clearInterval(this.setIntervalId)
            this.disabledSendBtn = false
            this.sendText = labelText
        }
    }
}
